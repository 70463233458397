import React from "react"
import { Link } from "gatsby"
import styled from "styled-components/macro"
import Logo from "../images/logo.svg"

const StyledHeader = styled.header`
  padding: 2rem;
  text-align: center;
`

const LogoType = styled.img`
  max-width: 100%;
`

// const ProductHuntLink = styled.a`
//  position: absolute;
//  right: 2rem;
//  top: 2rem;
// `

const Header = () => (
  <StyledHeader>
    <Link to="/">
      <LogoType src={Logo} alt="Teleport" />
    </Link>
    {/* <ProductHuntLink href="https://www.producthunt.com/posts/teleport-75a0445a-6a1d-4fcc-a77b-538d2bafff2b?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-teleport-75a0445a-6a1d-4fcc-a77b-538d2bafff2b" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=189086&theme=dark" alt="Teleport - Fun virtual backgrounds for Zoom | Product Hunt Embed" width="200px" /></ProductHuntLink> */}
  </StyledHeader>
)

export default Header
