import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled, {
  createGlobalStyle,
  ThemeProvider,
} from "styled-components/macro"
import "normalize.css"
import "../components/fontawesome"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Header from "./header"
import { BaseTheme } from "../components/theme"

const GlobalStyle = createGlobalStyle`
  body, html {
    line-height: 1.45;
    font-size: 16px;
    line-height: 1.45;
    font-family: ${props => props.theme.fonts.sansSerif};
    background-color: ${props => props.theme.colors.background};
    font-weight: ${props => props.theme.fontWeights.normal};
    background-color: ${props => props.theme.colors.background};
    color: ${props => props.theme.colors.text};
  }
  h1{
    font-weight: ${props => props.theme.fontWeights.black};
  }
  
  `

const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: stretch;
  align-items: stretch;
  min-height: 100vh;
`

const StyledMain = styled.main`
  padding: 2rem;
  flex-grow: 1;
  padding-top: 0;
`

const StyledFooter = styled.footer`
  padding: 1rem;
  color: ${props => props.theme.colors.grays[3]};
  font-size: ${props => props.theme.fontSizes[1]};
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  a {
    font-weight: ${props => props.theme.fontWeights.bold};
    color: ${props => props.theme.colors.grays[3]};
    text-decoration: none;
    &:hover {
      transition: color 0.2s ease, border 0.2s ease;
      color: ${props => props.theme.colors.text};
      border-bottom: solid 2px ${props => props.theme.colors.text};
    }
  }
  @media ${props => props.theme.screenSizes.tablet} {
    flex-direction: row;
  }
`
const ZoomInfo = styled.div`
  margin-bottom: 1rem;
  text-align: center;
  a {
    font-weight: ${props => props.theme.fontWeights.normal};
  }
  @media ${props => props.theme.screenSizes.tablet} {
    margin-bottom: 0;
    text-align: left;
  }
`

const ContactLink = styled(Link)`
  display: block;
  margin-bottom: 1rem;
  @media ${props => props.theme.screenSizes.tablet} {
    display: inline-block;
    margin-bottom: 0;
    margin-right: 1rem;
  }
`

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={BaseTheme.dark}>
      <GlobalStyle />
      <StyledLayout>
        <Header />
        <StyledMain>{children}</StyledMain>
        <StyledFooter>
          <div>
            <FontAwesomeIcon icon={["fab", "canadian-maple-leaf"]} /> Made in
            Toronto by{" "}
            <a
              href="https://markallen.io"
              target="_blank"
              rel="noopener noreferrer"
            >
              Mark Allen
            </a>
          </div>
          <ZoomInfo>
            <ContactLink to="/contact">Suggest a destination</ContactLink>
            <a
              href="https://support.zoom.us/hc/en-us/articles/210707503-Virtual-Background"
              target="_blank"
              rel="noopener noreferrer"
            >
              How do I change my background in Zoom?
            </a>
          </ZoomInfo>
        </StyledFooter>
      </StyledLayout>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
