import { library } from "@fortawesome/fontawesome-svg-core"

// Solid weight icons
import {
  faLongArrowRight
} from "@fortawesome/pro-solid-svg-icons"

// Regular weight icons
import {
  faPlusSquare
} from "@fortawesome/pro-regular-svg-icons"

// Light weight icons
import {} from "@fortawesome/pro-light-svg-icons"

// Brand icons
import { faCanadianMapleLeaf } from "@fortawesome/free-brands-svg-icons"

library.add(
  faCanadianMapleLeaf,
  faLongArrowRight,
  faPlusSquare
)
